import deepFreeze from 'deep-freeze';
import { merge } from 'lodash';

import { Storage } from '../actions';

import { Storage as StorageInterface } from '../../interfaces/Storage';
import { CustomerData, UserData } from '../../interfaces/StorageItem';

import { ActionInterface } from '../../helpers/actionBuilder';
import { IS_PROD } from '../../helpers/constants';
import { deduplicate } from '../../helpers/deduplicate';

export const initialState: StorageInterface = {
  fetching: false,
  items: [],
  message: '',
};

/**
 * Empty downloaded storage
 */
const clear = () => initialState;

/**
 * Initiate fetching
 */
const fetching = (
  storage: StorageInterface = initialState,
): StorageInterface => {
  return merge({}, storage, { fetching: true, message: '' });
};

/**
 * Merge fetched storage into state
 */
const receiveStorageItems = (
  storage: StorageInterface = initialState,
  fetchedItems: CustomerData[] | UserData[] = [],
): StorageInterface => {
  let nextItems = [...fetchedItems, ...storage.items];
  nextItems = deduplicate(nextItems, '_id');
  nextItems = nextItems.sort(
    (a, b) => Number(new Date(a.created)) - Number(new Date(b.created)),
  );

  return {
    ...{},
    ...initialState,
    ...storage,
    items: nextItems,
  };
};

/**
 * Merge fetched storage into state
 */
const deleteStorageItem = (
  storage: StorageInterface = initialState,
  itemId: string,
): StorageInterface => {
  return {
    ...{},
    ...initialState,
    ...storage,
    items: storage.items.filter((item) => item._id !== itemId),
  };
};

/**
 * Complete fetching, success
 */
const fetchingSucceeded = (
  storage: StorageInterface = initialState,
): StorageInterface => {
  return merge({}, storage, { fetching: false });
};

/**
 * Complete fetching, failure
 */
const fetchingFailed = (
  storage: StorageInterface = initialState,
  message: string = initialState.message as string,
): StorageInterface => {
  return merge({}, storage, { fetching: false, message });
};

export default (
  state: StorageInterface = initialState,
  action: ActionInterface,
): StorageInterface => {
  if (!IS_PROD) {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  switch (action.type) {
    case Storage.clear:
      return clear();

    case Storage.fetching:
      return fetching(state);

    case Storage.receive:
      return receiveStorageItems(state, action.payload);

    case Storage.delete:
      return deleteStorageItem(state, action.payload);

    case Storage.fetchingDone:
      if (action.error) {
        return fetchingFailed(state, action.payload.message);
      } else {
        return fetchingSucceeded(state);
      }

    default:
      return state;
  }
};
