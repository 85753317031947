export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_PROD = process.env.NODE_ENV === 'production';
export const IS_TESTING = process.env.NODE_ENV === 'test';

export const APP_IDS = {
  analytics: 'qwpP8et895diWQ7f7i5tlkkm', // env file?
};

export default {
  APP_IDS,
  IS_DEV,
  IS_PROD,
  IS_TESTING,
};

// This is Keero's DSN
// export const DSN =
//   'https://1d6a3905c9b448f9b2034bcccbfba0fb@sentry.sipcentric.com/2';
