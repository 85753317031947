import * as storage from './storage/actions';
import * as team from './team/actions';
import * as teams from './teams/actions';
import * as toasts from './toasts/actions';
import * as user from './user/actions';
// import * as users from './users/actions';

export {
  storage,
  team,
  teams,
  toasts,
  user,
  // users,
};

export const Team = {
  clear: 'TEAM_CLEAR',
  fetching: 'TEAM_FETCHING',
  fetchingDone: 'TEAM_FETCHING_DONE',
  receive: 'TEAM_RECEIVE',
  receiveConversations: 'TEAM_RECEIVE_CONVERSATIONS',
};

export const Teams = {
  clear: 'TEAMS_CLEAR',
  fetching: 'TEAMS_FETCHING',
  fetchingDone: 'TEAMS_FETCHING_DONE',
  receive: 'TEAMS_RECEIVE',
};

export const Toasts = {
  clear: 'TOASTS_CLEAR',
  preremove: 'TOASTS_PREREMOVE',
  push: 'TOASTS_PUSH',
  remove: 'TOASTS_REMOVE',
  shift: 'TOASTS_SHIFT',
};

export const Storage = {
  clear: 'STORAGE_CLEAR',
  delete: 'STORAGE_DELETE',
  fetching: 'STORAGE_FETCHING',
  fetchingDone: 'STORAGE_FETCHING_DONE',
  receive: 'STORAGE_RECEIVE',
};

export const User = {
  authenticating: 'USER_AUTHENTICATING',
  authenticatingDone: 'USER_AUTHENTICATING_DONE',
  fetching: 'USER_FETCHING',
  fetchingDone: 'USER_FETCHING_DONE',
  login: 'USER_LOGIN',
  logout: 'USER_LOGOUT',
  receive: 'USER_RECEIVE',
};

// export const Users = {
//   clear: 'USERS_CLEAR',
//   fetching: 'USERS_FETCHING',
//   fetchingDone: 'USERS_FETCHING_DONE',
//   receive: 'USERS_RECEIVE',
//   updateUserPresence: 'USERS_UPDATE_PRESENCE',
// };
