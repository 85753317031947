import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer } from 'redux';

import storage from './storage/reducers';
import team from './team/reducers';
import teams from './teams/reducers';
import toasts from './toasts/reducers';
import user from './user/reducers';
// import users from './users/reducers';

export const rootReducer = (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    storage,
    team,
    teams,
    toasts,
    user,
    // users,
  });
