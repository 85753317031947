import { State } from '../../interfaces/State';
import { CustomerData, UserData } from '../../interfaces/StorageItem';

import { Storage, Toasts } from '../actions';

import {
  ActionInterface,
  ErrorAction,
  SuccessAction,
} from '../../helpers/actionBuilder';
import { nvStorage } from '../../helpers/nvFetch';

/**
 * Empty downloaded team
 */
export const clearStorage = (): ActionInterface => {
  return new SuccessAction(Storage.clear).json;
};

/**
 * Get items from storage API
 */
export const fetchStorage = (
  customerId: string,
  type: 'customerdata' | 'userdata',
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    dispatch(new SuccessAction(Storage.fetching).json);

    let resource: string = type;
    if (type === 'customerdata') {
      resource += `?customerId=${customerId}`;
    }

    nvStorage(resource, getState().user.accessToken)
      .then((items: CustomerData[] | UserData[]) => {
        dispatch(new SuccessAction(Storage.receive, items).json);

        dispatch(new SuccessAction(Storage.fetchingDone).json);
      })
      .catch((error: Error) => {
        dispatch(new ErrorAction(Storage.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message: `There was a problem fetching this team's storage data: ${error.message}`,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Get item from storage API
 */
export const fetchStorageItem = (
  customerId: string,
  storageItemId: string,
  type: 'customerdata' | 'userdata',
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    // dispatch(new SuccessAction(Storage.fetching).json);

    let resource = `${type}/${storageItemId}`;
    if (type === 'customerdata') {
      resource += `?customerId=${customerId}`;
    }

    nvStorage(resource, getState().user.accessToken)
      .then((item: CustomerData | UserData) => {
        dispatch(new SuccessAction(Storage.receive, [item]).json);

        // dispatch(new SuccessAction(Storage.fetchingDone).json);
      })
      .catch((error: Error) => {
        // dispatch(new ErrorAction(Storage.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message: `There was a problem fetching this storage item: ${error.message}`,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Delete item from storage API
 */
export const deleteStorageItem = (
  customerId: string,
  itemId: string,
  type: 'customerdata' | 'userdata',
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    // dispatch(new SuccessAction(Storage.fetching).json);

    let resource = `${type}/${itemId}`;

    if (type === 'customerdata') {
      resource += `?customerId=${customerId}`;
    }

    nvStorage(resource, getState().user.accessToken, 'DELETE')
      .then((item: CustomerData | UserData) => {
        dispatch(new SuccessAction(Storage.delete, itemId).json);

        // dispatch(new SuccessAction(Storage.fetchingDone).json);
      })
      .catch((error: Error) => {
        // dispatch(new ErrorAction(Storage.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message: `There was a problem uploading this storage item: ${error.message}`,
            type: 'negative',
          }).json,
        );
      });
  };
};

/**
 * Upload item to storage API
 */
export const uploadStorageItem = (
  customerId: string,
  storageItem: CustomerData | UserData,
  type: 'customerdata' | 'userdata',
): any => {
  return (
    dispatch: (action: ActionInterface) => void,
    getState: () => State,
  ) => {
    // dispatch(new SuccessAction(Storage.fetching).json);

    let resource: string = type;
    let method: 'PUT' | 'POST' = 'POST';
    if (storageItem._id) {
      resource = `${type}/${storageItem._id}`;
      method = 'PUT';
    }

    if (type === 'customerdata') {
      resource += `?customerId=${customerId}`;
    }

    nvStorage(resource, getState().user.accessToken, method, storageItem)
      .then((item: CustomerData | UserData) => {
        dispatch(new SuccessAction(Storage.receive, [item]).json);

        // dispatch(new SuccessAction(Storage.fetchingDone).json);
      })
      .catch((error: Error) => {
        // dispatch(new ErrorAction(Storage.fetchingDone, error).json);

        dispatch(
          new SuccessAction(Toasts.push, {
            delay: 10000,
            message: `There was a problem uploading this storage item: ${error.message}`,
            type: 'negative',
          }).json,
        );
      });
  };
};
