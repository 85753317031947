import * as React from 'react';

import { ConnectedRouter as Router } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';

import Loading from '../Loading';

import { history } from '../../helpers/history';

import { Props, State } from './interfaces';

import styles from './styles.module.scss';

const App = React.lazy(() => import('../App'));
const Login = React.lazy(() => import('../Login'));
const Logout = React.lazy(() => import('../Logout'));
const Toast = React.lazy(() => import('../Toast'));

export default class Root extends React.Component<Props, State> {
  public render(): JSX.Element {
    const toasts = this.props.toasts;

    let toastNode = <React.Fragment />;

    if (toasts.length > 0) {
      const toast = toasts[0];

      toastNode = (
        <React.Suspense fallback={<Loading />}>
          <Toast
            buttons={toast.buttons}
            delay={toast.delay}
            id={toast.id}
            message={toast.message}
            removing={toast.removing}
            shift={this.props.shiftToast}
            type={toast.type}
          />
        </React.Suspense>
      );
    }

    return (
      <div className={styles.container}>
        <Router history={history}>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path='/login' component={Login} />

              <Route exact path='/logout' component={Logout} />

              <Route path='*' component={App} />
            </Switch>
          </React.Suspense>
        </Router>
        {toastNode}
      </div>
    );
  }
}
