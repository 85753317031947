import React from 'react';
import ReactDOM from 'react-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { Provider } from 'react-redux';

import Root from './components/Root';

import * as serviceWorker from './serviceWorker';

import './index.scss';

import { configureStore } from './store/configureStore';
const store = configureStore();

library.add(fal, far, fas);

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register();
