/**
 * Remove duplicate object from array based on key provided
 *
 * @param array Array to remove duplicate
 * @param key Key to find matches on. Default `id`
 * @return any[] Deduplicated array
 */
export const deduplicate = (array: any[], key: string = 'id'): any[] => {
  // Fuck this ugly code
  const deduplicated = array.filter(
    (item: any, index: number, self: any[]) =>
      self.findIndex((i) => i[key] === item[key]) === index,
  );

  return deduplicated;
};

export default deduplicate;
