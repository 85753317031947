import deepFreeze from 'deep-freeze';
import { merge } from 'lodash';

import { Team } from '../actions';

import { Team as TeamInterface } from '../../interfaces/Team';

import { ActionInterface } from '../../helpers/actionBuilder';
import { IS_PROD } from '../../helpers/constants';

export const initialState: TeamInterface = {
  address1: '',
  address2: '',
  city: '',
  company: '',
  created: new Date(),
  email: '',
  enabled: true,
  fetching: false,
  firstName: '',
  id: '',
  lastName: '',
  links: {
    callBundles: '',
    calls: '',
    creditStatus: '',
    endpoints: '',
    linkedUsers: '',
    outgoingCallerIds: '',
    phoneBook: '',
    phoneNumbers: '',
    preferences: '',
    recordings: '',
    sms: '',
    sounds: '',
    timeIntervals: '',
  },
  message: '',
  partnerCompany: '',
  partnerId: '',
  postcode: '',
  properties: '',
  telephone: '',
  type: 'customer',
  uri: '',
  userEmailUpdatable: false,
};

/**
 * Empty downloaded team
 */
const clear = () => initialState;

/**
 * Initiate fetching
 */
const fetching = (team: TeamInterface = initialState): TeamInterface => {
  return merge({}, team, { fetching: true, message: '' });
};

/**
 * Replace state with fetched team
 */
const receiveTeam = (
  fetchedTeam: TeamInterface = initialState,
): TeamInterface => {
  return merge({}, initialState, fetchedTeam);
};

/**
 * Complete fetching, success
 */
const fetchingSucceeded = (
  team: TeamInterface = initialState,
): TeamInterface => {
  return merge({}, team, { fetching: false });
};

/**
 * Complete fetching, failure
 */
const fetchingFailed = (
  team: TeamInterface = initialState,
  message: string = initialState.message as string,
): TeamInterface => {
  return merge({}, team, { fetching: false, message });
};

export default (
  state: TeamInterface = initialState,
  action: ActionInterface,
): TeamInterface => {
  if (!IS_PROD) {
    // Ensure state never gets mutated
    deepFreeze(state);
  }

  switch (action.type) {
    case Team.clear:
      return clear();

    case Team.fetching:
      return fetching(state);

    case Team.receive:
      return receiveTeam(action.payload);

    case Team.fetchingDone:
      if (action.error) {
        return fetchingFailed(state, action.payload.message);
      } else {
        return fetchingSucceeded(state);
      }

    default:
      return state;
  }
};
