import { APP_IDS } from './constants';

// import { CustomerData, UserData } from '../interfaces/StorageItem';

// const { partnerID } = require('../partner.json');
const partnerID = '1';

interface CustomHeaders extends Partial<Headers> {
  Accept?: string;
  'Access-Control-Allow-Origin'?: string;
  Authorization?: string;
  'Content-Type'?: string;
  'X-Nimvelo-App-Id'?: string;
  'X-Partner-Id'?: string;
  'X-WWW-Authenticate'?: string;
}

const doFetch = (
  resource = '',
  options = {},
  resolve: (data: any) => void,
  reject: (error: Error) => void,
) => {
  fetch(resource, options)
    .then(
      (response: Response): Promise<void | string> => {
        if (response.status < 400) {
          if (response.status === 204) {
            // 204 = No Content
            return response.text();
          }

          return response.json();
        }

        return response
          .json()
          .then(reject)
          .catch(() => {
            reject(new Error(`${response.status}: ${response.statusText}`));
          });
      },
    )
    .then(resolve)
    .catch(reject);
};

/**
 * Fetch something from Nimvelo API
 * Provides all necessary headers
 *
 * @param resource E.g. 'customers/5'
 * @param accessToken Encoded JWT
 * @param method POST, GET, PUT, DELETE
 * @param body JSON data. Ignored unless method is PUT or POST
 * @param customHeaders Additional request headers
 * @return Promise
 */
export const nvFetch = (
  resource: string,
  accessToken?: string,
  method: 'POST' | 'GET' | 'PUT' | 'DELETE' = 'GET',
  body?: any,
  customHeaders?: CustomHeaders,
): Promise<any> =>
  new Promise((resolve: () => void, reject: (error: Error) => void) => {
    const APIRoot = process.env.REACT_APP_PBX_API;

    // TODO retype this
    const options: any = { method };
    // const options: Partial<Request> = { method };

    const headers: CustomHeaders = {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': 'origin',
      'Content-Type': 'application/json',
      'X-Partner-Id': partnerID,
      'X-WWW-Authenticate': 'false',
      ...customHeaders,
    };

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    // Unset headers which are passed as undefined
    for (const key in customHeaders) {
      if (customHeaders[key as keyof CustomHeaders] === undefined) {
        delete headers[key as keyof CustomHeaders];
      }
    }

    options.headers = headers;

    if (method === 'PUT' || method === 'POST') {
      if (body) {
        options.body = body;

        if (!(body instanceof FormData)) {
          try {
            options.body = JSON.stringify(body);
          } catch (error) {
            // ¯\_(ツ)_/¯
          }
        }
      } else {
        reject(new Error('No body data'));
      }
    }

    const fullResource = `${APIRoot}/${resource}`;

    doFetch(fullResource, options, resolve, reject);
  });

/**
 * Interact with Storage API
 * Provides all necessary headers
 *
 * @param {string} resource Location foE.g. 'customers/5'
 * @param {string} accessToken Encoded JWT
 * @param {string} method POST, GET, PUT, DELETE (default: GET)
 * @param {object} body content to write to api
 * @return Promise
 */
export const nvStorage = (
  resource: string,
  accessToken?: string,
  method: 'POST' | 'GET' | 'PUT' | 'DELETE' = 'GET',
  body?: any,
  customHeaders?: CustomHeaders,
  // ): Promise<CustomerData[] | UserData[]> =>
): Promise<any> =>
  new Promise((resolve, reject) => {
    const APIRoot = process.env.REACT_APP_STORAGE_API;

    // TODO retype this
    const options: any = { method };
    // const options: Partial<Request> = { method };

    const headers: CustomHeaders = {
      'Content-Type': 'application/json',
      'X-Nimvelo-App-Id': APP_IDS.analytics,
      'X-Partner-Id': partnerID,
      ...customHeaders,
    };

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    // Unset headers which are passed as undefined
    for (const key in customHeaders) {
      if (customHeaders[key as keyof CustomHeaders] === undefined) {
        delete headers[key as keyof CustomHeaders];
      }
    }

    options.headers = headers;

    if (method === 'PUT' || method === 'POST') {
      if (body) {
        options.body = body;

        if (!(body instanceof FormData)) {
          try {
            options.body = JSON.stringify(body);
          } catch (error) {
            // ¯\_(ツ)_/¯
          }
        }
      } else {
        reject(new Error('No body data'));
      }
    }

    const fullResource = `${APIRoot}/${resource}`;

    doFetch(fullResource, options, resolve, reject);
  });
