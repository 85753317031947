import { isEqual, merge } from 'lodash';

import { State } from '../interfaces/State';

import { initialState as user } from './user/reducers';

export const initialStates = {
  user,
};

// Key to store under, helps avoid subdomain clashes
const product = 'StorageApiBrowser';

// Storage opt-in
// Careful; adding a key here that doesn't have a reducer will cause errors.
const whitelist: (keyof State)[] = ['user'];

/**
 * Check validity of fetched store
 *
 * Return false if a deserialised state object doesn't have all the same keys
 * as its matching initialState (from the reducer), else return true
 */
const validate = (
  state: any,
  stateName: keyof typeof initialStates,
): boolean => {
  return isEqual(merge({}, initialStates[stateName], state), state);
};

/**
 * Attempt to fetch state from localStorage, return only keys that are
 * whitelisted
 */
export const loadState = (): Partial<State> | undefined => {
  try {
    let serialisedState = localStorage.getItem(product);

    if (serialisedState === null) {
      serialisedState = sessionStorage.getItem(product);

      if (serialisedState === null) {
        return undefined;
      }
    }

    const state: State = JSON.parse(serialisedState) as State;

    const filteredState: Partial<State> = {};

    for (const reducer of whitelist) {
      if (validate(state[reducer], reducer as keyof typeof initialStates)) {
        filteredState[reducer] = state[reducer] as any;
      } else {
        // Stored state is malformed from initialState
        return undefined;
      }
    }

    return filteredState;
  } catch (error) {
    // Something failed
    // Play it safe and let initialState be constructed from scratch
    return undefined;
  }
};

/**
 * Put state into localStorage, store only whitelisted keys
 */
export const saveState = (state: State) => {
  const filteredState: Partial<State> = {};

  for (const reducer of whitelist) {
    filteredState[reducer] = state[reducer] as any;
  }

  // User doesn't want persistent storage
  if (state.user.rememberMe) {
    delete sessionStorage[product];

    try {
      const serialisedState = JSON.stringify(filteredState);

      localStorage.setItem(product, serialisedState);
    } catch (error) {
      // Ignore write errors
    }
  } else {
    delete localStorage[product];

    try {
      const serialisedState = JSON.stringify(filteredState);

      sessionStorage.setItem(product, serialisedState);
    } catch (error) {
      // Ignore write errors
    }
  }
};
